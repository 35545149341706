<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="instoragelist" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['instorageorderno','receiptdate']" scrollable
                    :scrollHeight="sHeight" :tableStyle="styleHeight" responsiveLayout="scroll" :loading="loading"
                    :lazy="true" :paginator="true" :rows="pagesize" :virtualScrollerOptions="vsOptionFun()"
                    :rowsPerPageOptions="[50, 100, 200, 500]" :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:25%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入入库单号模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.instorageorderno" @click="openInStorage(slotProps.data)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :closeParentMehod="closeMaximizable" :loadDataAfterMethod="loadDataAfter"
                :childNewDataConfig="childNewDataConfig" :dropOtherConfig="dropOtherConfig"
                :childTableFooter="childTableFooter" :CellEditCompleteMethod="cellEditComplete"
                :childExpandePageConfig="childExpandeConfig" :pageJsonConfig="pageJsonConfig" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="选择入库产品" v-model:visible="showProductDialog" :style="{width: '80vw'}" :maximizable="true"
        :modal="true">
        <p class="p-m-0">
            <DataTable :value="products" v-model:filters="productFilters1" v-model:selection="selectedProductRow"
                :metaKeySelection="false" :filters="productFilters1" filterDisplay="menu" dataKey="id"
                :globalFilterFields="['mpn']" scrollable scrollHeight="300px" responsiveLayout="scroll"
                :loading="loading" :lazy="true" :paginator="true" rows="50" :totalRecords="totalProductRecords"
                :first="firstProduct"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                @page="listProductPage($event)">
                <template #header>
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                            @click="clearProductFilter1()" />
                        <span class="p-input-icon-left mb-2">
                            <i class="pi pi-search" />
                            <InputText v-model="productFilters1['global'].value" placeholder="输入MPN查询"
                                @keyup.enter="customProductFilter();" style="width: 100%" />
                        </span>
                    </div>
                </template>
                <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
                </Column>
                <Column field="mpn" bodyStyle="min-width:160px;left:0px" headerStyle="min-width:160px;left:0px"
                    :showFilterOperator="false" header="MPN" frozen :filterMatchModeOptions="matchModes"
                    :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="sku" style="min-width:160px" :showFilterOperator="false" header="SKU"
                    :filterMatchModeOptions="matchModes" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="name" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:160px" header="产品名称" :sortable="true">
                    <template #body="slotProps">
                        <label class="oneline"
                            v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}</label>
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="allquantity" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                    style="min-width:160px" header="总库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="quantity" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                    style="min-width:160px" header="可售库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="futuresquantity" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                    style="min-width:160px" header="期货库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="processquantity" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                    style="min-width:160px" header="在产库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="shipmentquantity" :showFilterOperator="false" style="min-width:160px" header="在途库存"
                    :sortable="true" :filterMatchModeOptions="numMatchModes">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="subscribequantity" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                    style="min-width:160px" header="预定库存" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
                <Column field="url" style="min-width:100px" header="产品图片">
                    <template #body="slotProps">
                        <Image :src="getFilePatch(slotProps.data.url)" width="50" preview />
                    </template>
                </Column>
                <Column field="productstatusname" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                    style="min-width:150px" header="产品状态" :sortable="true">
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customProductFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
            </DataTable>
        </p>
        <p class="p-m-0">
            <DataTable :value="selectedProductRow" :metaKeySelection="false" filterDisplay="menu" dataKey="id"
                scrollable scrollHeight="350px" responsiveLayout="scroll" :loading="loading" :lazy="false"
                :paginator="false">
                <template #header>
                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                        <span class="text-xl text-900 font-bold">已选择产品</span>
                    </div>
                </template>
                <Column field="mpn" bodyStyle="min-width:160px;left:0px" headerStyle="min-width:160px;left:0px"
                    header="MPN" frozen>
                </Column>
                <Column field="sku" style="min-width:160px" header="SKU">
                </Column>
                <Column field="name" style="min-width:160px" header="产品名称">
                    <template #body="slotProps">
                        <label class="oneline"
                            v-tooltip.top="{ value: slotProps.data.name, disabled: false }">{{slotProps.data.name}}</label>
                    </template>
                </Column>
                <Column field="allquantity" style="min-width:160px" header="总库存">
                </Column>
                <Column field="quantity" style="min-width:160px" header="可售库存">
                </Column>
                <Column field="futuresquantity" style="min-width:160px" header="期货库存">
                </Column>
                <Column field="processquantity" style="min-width:160px" header="在产库存">
                </Column>
                <Column field="shipmentquantity" style="min-width:160px" header="在途库存">
                </Column>
                <Column field="subscribequantity" style="min-width:160px" header="预定库存">
                </Column>
                <Column field="url" style="min-width:100px" header="产品图片">
                    <template #body="slotProps">
                        <Image :src="getFilePatch(slotProps.data.url)" width="50" preview />
                    </template>
                </Column>
                <Column field="productstatusname" style="min-width:150px" header="产品状态">
                </Column>
                <template #footer> 共 {{ selectedProductRow ? selectedProductRow.length : 0 }} 条记录.</template>
            </DataTable>
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeShipmentData" />
                <Button label="导入" icon="pi pi-check" @click="importInventoryInStorage" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import Image from 'primevue/image';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import inventoryinstoragePageJson from "@/data/inventoryinstorageConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '739';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                butLoading: false,
                recordsubject: '',
                readOnly: true,
                displayMaximizable: false,
                displayQueryPlan: false,
                showProductDialog: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                productFilters1: null,
                currentRow: {
                    bindpage: 'inventoryinstorage',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                childNewDataConfig: {
                    products: false,
                    files: true,
                },
                pageJsonConfig: {},
                selectedRow: ref(),
                selectedProductRow: ref(),
                importProductRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                totalProductRecords: 0,
                firstProduct: 0,
                listTitle: '盘点入库',
                selectedKey: {
                    739: true
                },
                expandedKey: null,
                volumeratio: 100000,
                instoragelist: [],
                products: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '入库操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建入库',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createInStorage();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delInStorage();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                dropOtherConfig: {
                    instoragecurrency: 'instorageexchangerate',
                    fobcurrency: 'fobexchangerate',
                },
                childTableFooter: {
                    products: {
                        platformsku: '合计',
                        actualinstoragequantity: 0,
                        allweight: 0,
                        allvolume: 0,
                    }
                },
                childExpandeConfig: {},
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getinventoryinstorage(sessionStorage.getItem("appkey")).then(data => {
                    console.log('getinventoryinstorage....');
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    console.log(this.pageJsonConfig);
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0]['childExpandeConfig'];
                    if (data.page['region_3']['left']['element'][0]['volumeratio']) {
                        this.volumeratio = Number(data.page['region_3']['left']['element'][0]['volumeratio']);
                    }
                    this.initFilters1();
                    this.loadMenuTree();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            initProductFilters1() {
                this.productFilters1 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'mpn': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'sku': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'name': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'allquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'futuresquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'processquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'quantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'shipmentquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'subscribequantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'productstatusname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                }
            },
            clearProductFilter1() {
                this.initProductFilters1();
                this.loadProductData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('738', this.currentMenuId, this.$route, this.loadData, this.setlistTitleFun, this
                    .setloadMenuTree);
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '盘点入库', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            customProductFilter() {
                setTimeout(() => {
                    this.loadProductData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            listProductPage(event) {
                var page = event.page;
                this.loadProductData(page + 1);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: []
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'instorageorderno',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{inventoryinstoragelist(where:" + JSON.stringify(listwhere) +
                    "){id instorageorderno applydate storagewarehousename instoragecurrencyname instorageexchangerate fobcurrencyname fobexchangerate receiptdate rateprofit storename operatorname}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.instoragelist = jsonData.data.inventoryinstoragelist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            closeShipmentData() {
                this.selectedProductRow = null;
                this.showProductDialog = false;
            },
            importInventoryInStorage() {
                if (!this.selectedProductRow) {
                    MessageTip.warnmsg('请选择入库产品');
                    return;
                }
                var importData = {
                    data: []
                };
                for (var i = 0; i < this.selectedProductRow.length; i++) {
                    importData.data.push({
                        productid: this.selectedProductRow[i]['id']
                    });
                }
                if (importData.data.length > 0) {
                    this.butLoading = true;
                    requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid"))
                        .post(
                            '/calf/plat/InventoryInstorageImport?appkey=' + sessionStorage.getItem("appkey"), importData
                        )
                        .then(
                            res => {
                                console.log(res);
                                this.butLoading = false;
                                if (res.data.errcode == "0") {
                                    var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                    console.log('importInventoryInStorage:');
                                    console.log(retObj);
                                    this.importProductRow = retObj;
                                    this.recordsubject = '新建入库';
                                    this.currentRow = {
                                        bindpage: 'inventoryinstorage',
                                        id: '-1',
                                        workflowid: '',
                                        flowguid: '',
                                        boxid: '',
                                        stateid: '',
                                        flowid: '',
                                    };
                                    this.readOnly = false;
                                    this.showProductDialog = false;
                                    this.displayMaximizable = true;
                                } else {
                                    MessageTip.warnmsg(res.data);
                                }
                            });
                }
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData) {
                console.log('loadDataAfter...');
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    console.log(v_mainelements);
                    console.log(v_childelements);
                    console.log(v_originalData);
                    console.log(this.importProductRow);
                    for (var k2 = 0; k2 < v_childelements.length; k2++) {
                        if (v_childelements[k2]['name'] == 'products') {
                            v_childelements[k2]['rows'] = [];
                            for (var i = 0; i < this.importProductRow.length; i++) {
                                var actualinstoragequantity = 0;
                                var expectedinstoragequantity = 0;
                                if (this.importProductRow[i]['actualinstoragequantity']) {
                                    actualinstoragequantity = Number(this.importProductRow[i][
                                        'actualinstoragequantity'
                                    ]);
                                }
                                if (this.importProductRow[i]['expectedinstoragequantity']) {
                                    expectedinstoragequantity = Number(this.importProductRow[i][
                                        'expectedinstoragequantity'
                                    ]);
                                }
                                var productObj = {
                                    id: '-' + this.importProductRow[i]['productid'],
                                    shipmentid: -1,
                                    productid: this.importProductRow[i]['productid'] + '',
                                    producttype: this.importProductRow[i]['producttype'],
                                    shipmentproductid: -1,
                                    platformsku: this.importProductRow[i]['sku'],
                                    platformmpn: this.importProductRow[i]['mpn'],
                                    name: this.importProductRow[i]['name'],
                                    actualinstoragequantity: actualinstoragequantity,
                                    expectedinstoragequantity: expectedinstoragequantity,
                                    unitprice: this.importProductRow[i]['unitprice'],
                                    difference: 0,
                                    long: this.importProductRow[i]['len'],
                                    width: this.importProductRow[i]['width'],
                                    high: this.importProductRow[i]['high'],
                                    weight: this.importProductRow[i]['weight'],
                                    allweight: 0,
                                    volume: this.importProductRow[i]['volume'],
                                    url: this.importProductRow[i]['url'],
                                    allvolume: 0,
                                    sharingcost: 0,
                                    instorageprice: this.importProductRow[i]['instorageprice'],
                                    fobprice: this.importProductRow[i]['fobprice'],
                                    isonebox: this.importProductRow[i]['isonebox'],
                                    parts: [],
                                };
                                for (var k3 = 0; k3 < this.importProductRow[i]['parts'].length; k3++) {
                                    actualinstoragequantity = 0;
                                    expectedinstoragequantity = 0;
                                    if (this.importProductRow[i]['parts'][k3]['actualinstoragequantity']) {
                                        actualinstoragequantity = Number(this.importProductRow[i]['parts'][k3]);
                                    }
                                    if (this.importProductRow[i]['parts'][k3]['expectedinstoragequantity']) {
                                        expectedinstoragequantity = Number(this.importProductRow[i]['parts'][k3]);
                                    }
                                    var productPartObj = {
                                        id: '-' + this.importProductRow[i]['parts'][k3]['productpartsid'],
                                        shipmentid: -1,
                                        productid: this.importProductRow[i]['parts'][k3]['productid'] + '',
                                        shipmentproductid: -1,
                                        platformsku: this.importProductRow[i]['parts'][k3]['sku'],
                                        platformmpn: this.importProductRow[i]['parts'][k3]['mpn'],
                                        name: this.importProductRow[i]['parts'][k3]['name'],
                                        actualinstoragequantity: actualinstoragequantity,
                                        expectedinstoragequantity: expectedinstoragequantity,
                                        unitprice: this.importProductRow[i]['parts'][k3]['unitprice'],
                                        difference: 0,
                                        long: this.importProductRow[i]['parts'][k3]['len'],
                                        width: this.importProductRow[i]['parts'][k3]['width'],
                                        high: this.importProductRow[i]['parts'][k3]['high'],
                                        weight: this.importProductRow[i]['parts'][k3]['weight'],
                                        volume: this.importProductRow[i]['parts'][k3]['volume'],
                                        url: this.importProductRow[i]['parts'][k3]['url'],
                                        allweight: 0,
                                        allvolume: 0,
                                        sharingcost: this.importProductRow[i]['parts'][k3]['sharingcost'],
                                        instorageprice: this.importProductRow[i]['parts'][k3]['instorageprice'],
                                        fobprice: this.importProductRow[i]['parts'][k3]['fobprice'],
                                        isonebox: this.importProductRow[i]['parts'][k3]['isonebox'],
                                        productpartsid: this.importProductRow[i]['parts'][k3]['productpartsid'],
                                        matching: this.importProductRow[i]['parts'][k3]['matching'],
                                    };
                                    productObj.parts.push(productPartObj);
                                }
                                v_childelements[k2]['rows'].push(productObj);
                            }
                        }
                    }
                }
            },
            createInStorage() {
                this.selectedProductRow = null;
                this.products = [];
                this.importProductRow = [];
                this.initProductFilters1();
                this.loadProductData(1);
                this.showProductDialog = true;
            },
            delInStorage() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要删除入库单');
                    return;
                }
                var instorageIds = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    instorageIds.push({
                        "id": this.selectedRow[k1]["id"]
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "delete{inventoryinstoragelist(o:" + JSON.stringify(instorageIds) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadData(1);
                        MessageTip.successmsg('删除成功');
                    } else {
                        MessageTip.warnmsg('删除失败');
                    }
                });
            },
            loadProductData(v_page) {
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: []
                };
                for (var key in this.productFilters1) {
                    if (key == 'global' && this.productFilters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'mpn',
                            value: this.productFilters1[key]['value'],
                            operation: this.productFilters1[key]['matchMode']
                        });
                    }
                    if (this.productFilters1[key]['constraints']) {
                        for (var i = 0; i < this.productFilters1[key].constraints.length; i++) {
                            if (this.productFilters1[key].constraints[i].value && this.productFilters1[key].constraints[
                                    i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.productFilters1[key].constraints[i].value,
                                    operation: this.productFilters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{stockproductlist(where:" + JSON.stringify(listwhere) +
                    "){id mpn sku name fobprice allquantity futuresquantity processquantity quantity shipmentquantity subscribequantity url productstatusname storeid}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.products = jsonData.data.stockproductlist;
                        this.totalProductRecords = jsonData.page.allrecord;
                        this.firstProduct = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            openInStorage(v_data) {
                this.recordsubject = '入库单' + v_data.instorageorderno;
                this.currentRow = {
                    bindpage: 'inventoryinstorage',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.readOnly = true;
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj, v_childelements,
                v_alonechildelements, v_elements) {
                console.log('cellEditCompleteMethod..');
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                console.log(v_childelements);
                console.log(v_alonechildelements);
                console.log(v_elements);
                if (tablename != 'products') {
                    return;
                }
                if (tablename == 'products' && field == 'actualinstoragequantity' && rowdata['parts']) {
                    var actualinstoragequantitynum = Number(rowdata['actualinstoragequantity']);
                    if (!isNaN(actualinstoragequantitynum)) {
                        for (var i1 = 0; i1 < rowdata['parts'].length; i1++) {
                            var matchingnum1 = Number(rowdata['parts'][i1]['matching']);
                            var volumenum1 = Number(rowdata['parts'][i1]['volume']);
                            if (!isNaN(matchingnum1)) {
                                var actualinstoragequantity1 = matchingnum1 * actualinstoragequantitynum;
                                rowdata['parts'][i1]['actualinstoragequantity'] = actualinstoragequantity1;
                                if (!isNaN(volumenum1)) {
                                    rowdata['parts'][i1]['allvolume'] = actualinstoragequantity1 * volumenum1;
                                }
                            }
                        }
                    }
                }
                if (tablename == 'products') {
                    this.productsvolumecalculate(rowdata);
                }
            },
            productsvolumecalculate(rowdata) {
                var volumenum = Number(rowdata['volume']);
                var weightnum = Number(rowdata['weight']);
                var quantitynum = Number(rowdata['actualinstoragequantity']);
                if (!isNaN(volumenum) && !isNaN(quantitynum)) {
                    rowdata['allvolume'] = (quantitynum * volumenum).toFixed(4);
                }
                if (!isNaN(weightnum) && !isNaN(quantitynum)) {
                    rowdata['allweight'] = (quantitynum * weightnum).toFixed(2);
                }
                console.log('productsvolumecalculate..' + rowdata['volume']);
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
            Image,
        }
    }
</script>